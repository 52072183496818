// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-subtle-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

@each $color,
$value in $theme-colors {
    .bg-dark-#{$color} {
        background-color: darken(($value), 10%) !important;
    }
}