// 
// _buttons.scss
// 

button,
a {
    outline: none !important;
}


.user {
    &:hover {
        color: var(--#{$prefix}header-item-color) !important;
    }
}

.btn-light {
    background-color: $gray-200;
    color: var(--#{$prefix}emphasis-color) !important;
    border: 1px solid transparent;
    &.show {
        background-color: var(--#{$prefix}tertiary-bg) !important;
        color: var(--#{$prefix}emphasis-color) !important;
    }
    &:hover {
        background-color: var(--#{$prefix}tertiary-bg) !important;
        color: var(--#{$prefix}emphasis-color) !important;
        border-color: var(--#{$prefix}border-color) !important;
    }
    &:focus,
    &:active {
        background-color: var(--#{$prefix}tertiary-bg) !important;
        color: var(--#{$prefix}emphasis-color) !important;
        box-shadow: var(--#{$prefix}shadow-sm);
    }
}



.btn-check:checked+.btn{
    background-color: $gray-200;
    border-color: var(--#{$prefix}border-color);
}
.btn-check+.btn:hover{
    &:hover,
    &:focus,
    &:active {
        color: var(--#{$prefix}emphasis-color) !important;
        background-color: $gray-200;
        border-color: var(--#{$prefix}border-color);

      }
}

.btn-outline-light {
    border-color: var(--#{$prefix}border-color);
    color: var(--#{$prefix}emphasis-color) !important;

    &.active {
    color: var(--#{$prefix}emphasis-color) !important;
    background-color: $gray-200;

    }
    &:hover,
    &:focus,
    &:active {
        color: var(--#{$prefix}emphasis-color) !important;
        background-color: $gray-200;
        border-color: var(--#{$prefix}border-color) !important;
    }
}

//
// Split Dropdown buttons
//

@mixin button-variant-split($bg) {
    border: none;
}

@each $color, $value in $theme-colors {
    .btn-#{$color}.dropdown-toggle-split {
        @include button-variant-split($value);
    }
}


@mixin btn($bg) {
    color: $white;
    border-color: transparent;
    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
    }
    &:focus {
        box-shadow: 0 0 0 0.15rem rgba($bg, 0.5);
    }
}
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include btn($value);
    }
}


// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

.btn-outline-dark {
    color: var(--#{$prefix}emphasis-color) !important;
    &:hover {
        color: $light !important;
    }
}
.btn-subtle-dark{
  &:hover{
    color: $light !important;
  }
}


@each $color,
$value in $theme-colors {
    .btn-subtle-#{$color} {
        color: $value;
        background-color: rgba(($value), 0.25);
        &:hover{
          color: $white;
          background-color: ($value);
        }
    }
}


// social buttons


/* Social Buttons */

.btn-social-icon .btn{
  width: 36px;
  height: 36px;
  padding-left: 10px;
}
.btn-facebook {
  color: $white !important;
  background-color: #3b5998 !important;
}
.btn-twitter {
  color: $white !important;
  background-color: #00aced !important;
}
.btn-linkedin {
  color: $white !important;
  background-color: #007bb6 !important;
}
.btn-dribbble {
  color: $white !important;
  background-color: #ea4c89 !important;
}
.btn-googleplus {
  color: $white !important;
  background-color: #dd4b39 !important;
}
.btn-instagram {
  color: $white !important;
  background-color: #517fa4 !important;
}
.btn-pinterest {
  color: $white !important;
  background-color: #cb2027 !important;
}
.btn-dropbox {
  color: $white !important;
  background-color: #007ee5 !important;
}
.btn-flickr {
  color: $white !important;
  background-color: #ff0084 !important;
}
.btn-tumblr {
  color: $white !important;
  background-color: #32506d !important;
}
.btn-skype {
  color: $white !important;
  background-color: #00aff0 !important;
}
.btn-youtube {
  color: $white !important;
  background-color: #bb0000 !important;
}
.btn-github {
  color: $white !important;
  background-color: #171515 !important;
}

[data-bs-theme="dark"]{
  .btn-light {
    background-color: #21253e;
  }
  .btn-check:checked+.btn{
    background-color: #21253e;
}
.btn-outline-light {
  
  &.active {
    background-color:#21253e;

    }
  &:hover,
  &:focus,
  &:active {
      background-color:#21253e;
  }
}
.btn-check+.btn:hover{
  &:hover,
  &:focus,
  &:active {
      background-color: #21253e;

    }
  }
}